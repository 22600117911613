<template>
    <div class="index">
        <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022002995号</a>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.index{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #fff;
    }
</style>