import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import index from "../views/index.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/index",
    name: "index",
    component: index,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/map",
    name: "map",
    
    component: () =>
      import( "../views/map.vue"),
  },
  {
    path: "/houseDetail",
    name: "detailP",
    
    component: () =>
      import( "../views/houDetail.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    
    component: () =>
      import( "../views/detail.vue"),
  },
  {
    path: "/admin/add",
    name: "add",
    component: () =>
      import( "../views/add.vue"),
  },
  {
    path: "/admin/list",
    name: "list",
    component: () =>
      import( "../views/list.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
