import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import from from "./styles/index.scss";
import animated from "./styles/animate.css";
import { Toast, Field, Form, Button, Cell, CellGroup,Picker,ActionSheet,Uploader , Swipe, SwipeItem,Icon,Loading} from "vant";
Vue.config.productionTip = false;
Vue.use(animated)
  .use(Toast)
  .use(Field)
  .use(Form)
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Picker)
  .use(ActionSheet)
  .use(Uploader)
  .use(Swipe)
  .use(SwipeItem)
  .use(Icon)
  .use(Loading)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
