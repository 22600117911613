import request from '@/utils/request'
//项目
export function project(query) {
    return request({
      url: '/project',
      method: 'get',
      params: query
    })
  }
//系列
  export function series(query) {
    return request({
      url: '/series',
      method: 'get',
      params: query
    })
  }
//获取上传签名
  export function getOss(query) {
    return request({
      url: '/ali/sts',
      method: 'get',
      params: query
    })
  }
  //添加
  
  export function addPro(query) {
    return request({
      url: '/project',
      method: 'post',
      data: query
    })
  }