import axios from 'axios';
import {Toast} from 'vant'

// 登录请求方法
const BASE_URL = process.env.VUE_APP_API;
const service = axios.create({
  baseURL:BASE_URL,
  timeout: 10000, 

})

// Toast.loading({
//     duration:10000,
//     message: '加载中...',
//     forbidClick: true,
//     loadingType: 'spinner',
// });
  
service.interceptors.response.use(
  response => {
    Toast.clear();
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '0') {
       
      return Promise.reject(new Error(res.result || res.message || 'Error'))
    } else {
      return res
    }

  },

  error => {
    Toast.clear();
    Toast('服务端异常，联系管理员！');
   
    return Promise.reject(error)
  }
)

export default service