<template>
  <div class="home">
    <ghmap></ghmap> 
    <div class="footer">
       <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022002995号</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ghmap from './map.vue'
export default {
  name: "Home",
  components: {
  ghmap
  },
};
</script>
<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
  }
</style>