<template>
  <div class="content">
    <div v-if="loading" class="loading">
      <!-- <van-loading color="#388c88" type="spinner" /> -->
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="container"></div>
    <div class="logo"></div>

    <!-- 渐变弹窗 -->
    <div class="left-content">
      <h3>寓见 <span>·</span> 高行</h3>
      <p>高行公寓房源入库总套数</p>
      <div class="num">
        <span>{{ totalNum }}</span
        ><i>套</i>
      </div>
      <ul class="ul">
        <li>
          <i></i><span class="li-name">寓见高行 · 原宿</span>
          <span class="li-num">{{ num1 }}</span>
        </li>
        <li>
          <i></i><span class="li-name">寓见高行 · 安居</span>
          <span class="li-num">{{ num2 }}</span>
        </li>
        <li>
          <i></i><span class="li-name">寓见高行 · 宜居</span>
          <span class="li-num">{{ num3 }}</span>
        </li>
        <li>
          <i></i><span class="li-name">寓见高行 · 品质</span>
          <span class="li-num">{{ num4 }}</span>
        </li>
        <li>
          <i></i><span class="li-name">寓见高行 · 卓越</span>
          <span class="li-num">{{ num5 }}</span>
        </li>
      </ul>
    </div>
    <!-- 移动端 -->
    <div class="block">
      <div class="header">
        <h3>寓见 <span>·</span> 高行</h3>
        <div class="logo"></div>
      </div>
    </div>
    <div class="block">
      <div class="bottom">
        <ul>
          <li>
            <i></i><span class="li-name">寓见高行 · 原宿</span>
            <span class="li-num">{{ num1 }}</span>
          </li>
          <li>
            <i></i><span class="li-name">寓见高行 · 安居</span>
            <span class="li-num">{{ num2 }}</span>
          </li>
          <li>
            <i></i><span class="li-name">寓见高行 · 宜居</span>
            <span class="li-num">{{ num3 }}</span>
          </li>
          <li>
            <i></i><span class="li-name">寓见高行 · 品质</span>
            <span class="li-num">{{ num4 }}</span>
          </li>
          <li>
            <i></i><span class="li-name">寓见高行 · 卓越</span>
            <span class="li-num">{{ num5 }}</span>
          </li>
        </ul>
        <div class="total">
          <p>高行公寓房源入库总套数</p>
          <div class="num">
            <span>{{ totalNum }}</span
            >套
          </div>
        </div>
      </div>
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated bounceInUp"
      leave-active-class="animated bounceOutDown"
    >
      <div class="popup" v-if="isShow" @click.self="hide">
        <div class="popup-detail">
          <div class="h3">
            <span class="circular">·</span>
            <p>{{ item.name }}</p>
            <span class="circular">·</span>
          </div>
          <van-swipe
            class="my-swipe"
            height="140"
            :autoplay="3000"
            indicator-color="white"
          >
            <van-swipe-item v-for="(image, index) in item.images" :key="index">
              <div
                class="image"
                @click.stop="goTo"
                :style="{
                  background:
                    'url(' + image.image + ')  no-repeat center center ',
                }"
              ></div>
            </van-swipe-item>
          </van-swipe>
          <p class="type" v-if="item.seriesId === 1">寓见高行·原宿</p>
          <p class="type" v-if="item.seriesId === 2">寓见高行·安居</p>
          <p class="type" v-if="item.seriesId === 3">寓见高行·宜居</p>
          <p class="type" v-if="item.seriesId === 4">寓见高行·品质</p>
          <p class="type" v-if="item.seriesId === 5">寓见高行·卓越</p>
          <table class="table" border="1">
            <tr v-for="(itemT, index) in item.apartmentType" :key="index">
              <td>{{ itemT.houseType }}</td>
              <td>{{ itemT.area }}</td>
              <td>{{ itemT.price }}</td>
            </tr>
          </table>
          <div class="userInfo">
            <span>联系人：{{ item.contact }}</span>
            <span>{{ item.phone }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader"
import { project } from "@/api/project"
import { _isMobile } from "@/utils/util"
export default {
  components: {},
  data() {
    return {
      map: {},
      makerList: [
        {
          seriesId: 1,
          houseQuantity: 25,
          name: "东力新村企业服务公寓",
          lon: 121.627195,
          lat: 31.287197,
        },
        {
          seriesId: 1,
          houseQuantity: 111,
          name: "高宜佳苑",
          lon: 121.58164,
          lat: 31.319256,
        },
        {
          seriesId: 2,
          houseQuantity: 11,
          name: "V领地",
          lon: 121.58164,
          lat: 31.319256,
        },
        {
          seriesId: 2,
          houseQuantity: 102,
          name: "安歆公寓",
          lon: 121.584217,
          lat: 31.312365,
        },
        {
          seriesId: 3,
          houseQuantity: 333,
          name: "朗诗寓",
          lon: 121.588136,
          lat: 31.314918,
        },
        {
          seriesId: 3,
          houseQuantity: 25,
          name: "和韵公寓",
          lon: 121.580195,
          lat: 31.298276,
        },
        {
          seriesId: 4,
          houseQuantity: 196,
          name: "宝华紫薇",
          lon: 121.587019,
          lat: 31.32843,
        },
        {
          seriesId: 4,
          houseQuantity: 40,
          name: "森兰名苑",
          lon: 121.59084,
          lat: 31.290748,
        },
        {
          seriesId: 5,
          houseQuantity: 206,
          name: "雅诗阁",
          lon: 121.594285,
          lat: 31.311532,
        },
        {
          seriesId: 5,
          houseQuantity: 128,
          name: "东源丽晶",
          lon: 121.621585,
          lat: 31.28447,
        },
      ],

      markers: [], //覆盖物
      isShow: false,
      color: ["#4ac913", "#0bceeb", "#ff708b", "#ff9650", "#cf61dc"],
      list: [1, 2, 3],
      cliDOMTips: "", //点击的dom
      marker: "", //信息
      activeIndex: 1,
      item: {},
      interval: "",
      cliMaker: "", //点击的maker 需要显示隐藏
      locationList: [
        "top",
        "bottom",
        "left",
        "left",
        "top",
        "right",
        "right",
        "right",
        "top",
        "bottom",
        "top",
        "left",
        "top",
      ],
      loading: true,
      totalNum: 0,
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      num5: 0,
      markerDom: "",
      markerDom1: "",
    }
  },
  computed: {},
  watch: {},
  methods: {
    goTo() {
      this.$router.push({
        path: "/detail",
        query: {
          id: this.item.id,
        },
      })
    },
    hide() {
      this.isShow = false
    },
    //初始化地图
    async initMap() {
      let { result } = await project({ id: "" })
      let arr = []
      let last
      result.forEach((el) => {
        if (el.id !== 14) {
          arr.push(el)
        } else {
          last = el
        }
      })
      console.log(arr)
      arr.push(last)
      this.getNumData(arr)
      this.makerList = result || []
      AMapLoader.load({
        key: "d30868e60c96ca79bc2f652aa952832e", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            resizeEnable: true,
            zooms: _isMobile() ? [10, 20] : [10, 20],
            zoom: _isMobile() ? 13 : 14,
            center: _isMobile()
              ? [121.598626, 31.308949]
              : [121.606626, 31.308949],
            pitch: 0, // 地图俯仰角度，有效范围 0 度- 83 度
            viewMode: "2D", // 地图模式,
            mapStyle: "amap://styles/79b967794e54ebea963d94de5ab11ffc",
          })
          if (!_isMobile()) {
            //  this.map.setLimitBounds(this.map.getBounds());
          }

          this.addMarker(this.makerList)
          this.addZoomchange()
          let _this = this
          this.map.on("click", function (ev) {
            // _this.map.remove(_this.marker)
          })
          this.loading = false
        })
        .catch((e) => {
          console.log(e)
        })
    },
    //
    addZoomchange() {
      this.map.on("zoomend", (e) => {
        if (this.map.getZoom() > 15) {
          // this.map.remove(this.markers);
        } else {
          // this.map.add(this.markers);
        }
      })
    },
    getNumData(data) {
      console.log(data)
      data.forEach((e, index) => {
        if (e.seriesId === 1) {
          this.num1 += e.houseQuantity
        }
        if (e.seriesId === 2) {
          this.num2 += e.houseQuantity
        }
        if (e.seriesId === 3) {
          this.num3 += e.houseQuantity
        }
        if (e.seriesId === 4) {
          this.num4 += e.houseQuantity
        }
        if (e.seriesId === 5) {
          this.num5 += e.houseQuantity
        }
      })
      this.totalNum = this.num1 + this.num2 + this.num3 + this.num4 + this.num5
    },
    //清除信息弹窗

    //添加信息弹窗
    addDetail(item, w) {
      if (this.interval !== "") {
        clearInterval(this.interval)
        this.activeIndex = 1
      }

      if (this.marker !== "") {
        this.map.remove(this.marker)
      }
      // 表格
      let tr = ""
      item.apartmentType.forEach((el) => {
        let dd = `
         <tr>
          <td>${el.houseType}</td>
          <td>${el.area} </td>
           <td>${el.price} </td>
        </tr>
        `
        tr += dd
      })
      // 轮播图片
      let imgList = ""
      item.images.forEach((el) => {
        let img = `
        <div class="swiper-sw animated fadeIn" style=" background: url(${el.image})  no-repeat center center "></div>
        `
        imgList += img
      })
      // 轮播点
      let spanList = ""
      item.images.forEach((el) => {
        let item = `
          <span class="init-pagination">·</span>
        `
        spanList += item
      })
      //类型
      let typeName = ""
      if (item.seriesId === 1) {
        typeName = '<p class="type">寓见高行·原宿</p>'
      } else if (item.seriesId === 2) {
        typeName = '<p class="type">寓见高行·安居</p>'
      } else if (item.seriesId === 3) {
        typeName = '<p class="type">寓见高行·宜居</p>'
      } else if (item.seriesId === 4) {
        typeName = '<p class="type">寓见高行·品质</p>'
      } else if (item.seriesId === 5) {
        typeName = '<p class="type">寓见高行·卓越</p>'
      }
      var position = new AMap.LngLat(item.lon, item.lat)
      // 主体
      let doc = `  <div class="detail animated fadeInUp" style="left:0px">
      <span class="close"  ></span>
      <div class="h3"><span class="circular">·</span><p>${item.name}</p><span class="circular">·</span></div>
    <div class="m-swiper">
        <div class="swiper-list">
         ${imgList}
        </div>
       
    </div>
     <div class="pagination">
         ${spanList}
        </div>
     ${typeName}
      <p class="number"><i>${item.houseQuantity}</i>套</p>
      <table class="table" border="1">
      ${tr}
        
      </table>
      <div class="userInfo">
        <span>联系人：${item.contact}</span>
        <span>${item.phone}</span>
      </div>
    </div>`
      this.marker = new AMap.Marker({
        position: position,
        content: doc,
        anchor: "top-left",
      })
      this.map.add(this.marker)

      let _this = this
      //点击关闭
      setTimeout(() => {
        document
          .querySelector(".swiper-list")
          .addEventListener("click", function (e) {
            clearInterval(_this.interval)
            _this.$router.push({
              path: "houseDetail",
              query: {
                id: item.id,
              },
            })
          })
        document
          .querySelector(".close")
          .addEventListener("click", function (e) {
            _this.map.add(_this.cliMaker)
            document.querySelector(".detail").classList.add("fadeOut")
            setTimeout(() => {
              _this.map.remove(_this.marker)
            }, 1000)
            _this.markerDom.style.opacity = 1
            _this.markerDom1.style.opacity = 1
            e.stopPropagation()
            clearInterval(_this.interval) //清除定时
          })
      }, 1000)

      //轮播图
      this.interval = setInterval(() => {
        //  console.log(document.querySelectorAll('.swiper-sw'));
        if (item.images.length === 1 || item.images.length === 0) {
          clearInterval(_this.interval)
          return
        }
        let list = document.querySelectorAll(".swiper-sw")
        let pagination = document.querySelectorAll(".init-pagination")
        if (pagination.length > 0) {
          pagination.forEach((el) => {
            el.style.color = "#989898"
          })
          pagination[_this.activeIndex].style.color = "#388c88"
        }
        list.forEach((el) => {
          el.style.display = "none"
        })
        console.log(item.images.length)
        list[_this.activeIndex].style.display = "block"
        if (_this.activeIndex + 1 === item.images.length) {
          _this.activeIndex = 0
        } else {
          _this.activeIndex++
        }
      }, 4000)
    },

    //添加覆盖物
    addMarker(data) {
      let _this = this
      let a = []
      let location = "left" //方向
      data.forEach((el, index) => {
        let position = new AMap.LngLat(el.lon, el.lat)
        let markerId = el.id === 14 ? "markerId" : "init"

        // 点标记显示内容，HTML要素字符串
        let markerContent = `
        <div id=${markerId} class="custom-content-marker   ${_this.locationList[index]}">
            <div class="iconT" style="background-color:${
              this.color[el.seriesId - 1]
            }">

            </div>
            <div class="txt">
             <p>${el.name}</p> 
            <span><i style="color:${this.color[el.seriesId - 1]}">${
          el.houseQuantity
        }</i>套</span>
            </div>
           
        </div>
       
        `
        // <div class="detail animated bounce">123</div>
        let circle = `
          <div id=${markerId + "1"}  class="circleHtml"  style="background:${
          this.color[el.seriesId - 1]
        }"></div>`
        let marker = new AMap.Marker({
          position: position,
          content: markerContent,
          anchor: "top-center",
          zIndex: el.id === 14 ? 9999 : 100 ,
          offset: new AMap.Pixel(0, _isMobile() ? -64 : -94),
        })

        let circleMarker = new AMap.Marker({
          position: position,
          content: circle,
          anchor: "top-center",
          zIndex: el.id === 14 ? 999 : 99,
          offset: new AMap.Pixel(0, 0),
        })
        marker.detail = el //参数
        marker.on("click", function (e) {
          let offsetWidth = e.originEvent.target.offsetWidth
          let d = e.target.detail
          //定位地图中心
          _this.map.setCenter([d.lon, d.lat])
          console.log(_isMobile())
          if (_isMobile()) {
            _this.isShow = true
            _this.item = d
          } else {
            if (_this.markerDom === "") {
              _this.markerDom = document.getElementById("markerId")
              _this.markerDom1 = document.getElementById("markerId1")
            }
            _this.markerDom.style.opacity = 0
            _this.markerDom1.style.opacity = 0
            if (_this.cliMaker === "") {
              _this.cliMaker = e.target
              _this.map.remove(_this.cliMaker)
            } else {
              _this.map.add(_this.cliMaker)
              _this.cliMaker = e.target
              _this.map.remove(_this.cliMaker)
            }
            _this.addDetail(d, offsetWidth)
            // _this.addDetail(d, offsetWidth);
            // //透明不能隐藏
            // let doc = e.originEvent.target;
            // if (doc.style.opacity === "0") {
            //   return;
            // }
            // if (_this.cliDOMTips !== "") {
            //   _this.cliDOMTips.style.opacity = 1;
            // }
            // _this.cliDOMTips = doc;
            // _this.addDetail(d, offsetWidth);
            // setTimeout(() => {
            //   doc.style.opacity = 0;
            // }, 200);
          }
        })
        this.markers.push(marker)
        a.push(circleMarker)
      })
      this.map.add(this.markers)

      console.log(this.map.getLayers())
      this.map.add(a)
    },
  },
  created() {},
  mounted() {
    this.initMap()
  },
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.content {
  width: 100%;
  height: 100%;
}
#container {
  width: 100%;
  height: 100%;
}
/deep/.custom-content-marker {
  display: flex;
  background: #fffff8;
  border-radius: 5px;
  padding: 5px;
  width: max-content;
  margin-bottom: 16px;
  border-radius: 8px;
  transition: all 0.5s linear;
  // bad6cc
  box-shadow: 0px 5px 8px 4px #adc5be;
  align-items: center;
  margin-left: -7px;
  &::after {
    display: block;
    content: "";
    border-width: 8px 8px 8px 8px;
    border-style: solid;
    border-color: #fffff8 transparent transparent transparent;

    /* 定位 */
    position: absolute;
    // left: 100%;
    // top: 95%;
    // margin-left: -1px;
  }

  .iconT {
    width: 38px;
    height: 38px;
    border-radius: 8px;
    background-image: url("../assets/house.png");
    background-position: center center;
    background-size: 26px;
    background-repeat: no-repeat;
  }
  .txt {
    flex: 1;
    text-align: center;
    color: #388c88;
    font-weight: 700;
    padding: 0 0 0 5px;
    p {
      font-size: 1.1rem;
    }
    span {
      font-size: 1rem;
      i {
        font-size: 1.4rem;
        font-style: inherit;
        line-height: 1rem;
      }
    }
  }
}
/deep/.amap-logo {
  display: none !important;
}
.popup {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  color: #5b5b5b;
  z-index: 9999999;

  .popup-detail {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 320px;
    background: #fff;
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    padding: 0 20px;
    z-index: 99999;
    .my-swipe {
      .image {
        width: 100%;
        height: 100%;
        background-size: cover !important;
      }
    }
    .h3 {
      font-size: 1rem;
      color: #388c88;
      font-weight: 700;

      margin: 10px 0;
      text-align: center;
      line-height: 30px;
      margin: 0;
      p {
        display: inline-block;
        letter-spacing: 5px;
        text-indent: 5px;
      }
      .circular {
        display: inline-block;
        color: #388c88;
      }
    }
    .type {
      text-align: center;
      position: relative;
      margin: 5px 0;
      font-size: 1rem;
      border-top: 1px solid #5b5b5b;
      border-bottom: 1px solid #5b5b5b;
      letter-spacing: 5px;
      span {
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #5b5b5b;
        margin: 4px 30px;
      }
    }
    .table {
      border-collapse: collapse;
      width: 100%;
      max-height: 80px;
      tr {
        td {
          font-size: 12px;
          text-align: center;
          &:last-child {
            color: #ff8533;
          }
        }
      }
    }
    .userInfo {
      text-align: center;
      padding: 10px 0;
      span {
        padding: 0 5px;
      }
    }
  }
}
/deep/ .amap-marker-content {
  white-space: inherit !important;
}
/deep/ .detail {
  // position: fixed;
  //  left: 50%;
  // bottom: 50%;
  // margin-bottom: -196px;
  width: 305px;
  position: absolute;
  margin-left: 40px;
  // height: 392px;
  background: #fffff8;
  display: block;
  // margin-left: 30px;
  box-shadow: 0px 0px 11px 4px #adc5be;
  z-index: 99999;
  border-radius: 10px;
  color: #5b5b5b;
  padding: 10px;
  height: 444px;
  bottom: -260px;
  &::after {
    display: block;
    content: "";
    border-width: 8px 8px 8px 8px;
    border-style: solid;
    border-color: transparent #fffff8 transparent transparent;

    /* 定位 */
    position: absolute;
    left: -14px;
    top: 50%;
    margin-top: -8px;
  }
  .close {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../assets/svg/close.svg") no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 9999999;
  }
  .h3 {
    font-size: 1.2rem;
    color: #388c88;
    font-weight: 700;

    margin: 10px 0;
    text-align: center;
    line-height: 30px;
    p {
      display: inline-block;
      letter-spacing: 5px;
      text-indent: 5px;
    }
    .circular {
      display: inline-block;
      color: #388c88;
    }
  }
  .type {
    text-align: center;
    position: relative;
    margin: 10px 0;
    font-size: 1.4rem;
    border-top: 1px solid #5b5b5b;
    border-bottom: 1px solid #5b5b5b;
    span {
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #5b5b5b;
      margin: 4px 30px;
    }
  }
  .number {
    text-align: center;
    i {
      font-style: normal;
      font-size: 1.3rem;
      color: #ff8533;
      font-weight: 700;
    }
  }
  .table {
    border-collapse: collapse;
    width: 100%;
    max-height: 80px;
    tr {
      td {
        font-size: 12px;
        text-align: center;
        &:last-child {
          color: #ff8533;
        }
      }
    }
  }
  .userInfo {
    text-align: center;
    padding: 10px 0;
    span {
      padding: 0 5px;
    }
  }
}
/deep/ .circleHtml {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
/deep/ .wh_content {
  width: 285px;
  height: 150px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #fff;
}
/deep/ .m-swiper {
  width: 100%;
  height: 150px;
  .swiper-list {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-sw {
      display: none;
      // background: red;
      transition: all 1s linear;
      width: 100%;
      height: 100%;
      // background: url('https://hhfly.oss-cn-shanghai.aliyuncs.com/1628155110461.png')  no-repeat center center;
      background-size: cover !important;
      &:first-child {
        display: block;
      }
    }

    .swiper-active {
      display: block;
    }
  }
}
/deep/ .pagination {
  text-align: center;
  height: 30px;
  .init-pagination {
    display: inline-block;
    color: #989898;
    font-size: 75px;
    // margin: 0 5px;
    line-height: 30px;
    &:nth-child(1) {
      color: #388c88;
    }
  }
}
//
.left-content {
  position: fixed;
  left: 0;
  top: 0;
  width: 500px;
  height: 100%;
  padding: 113px 0 0 104px;
  background-image: linear-gradient(
    to right,
    rgba(72, 149, 145, 1) 20%,
    rgba(56, 140, 136, 0)
  );
  color: #fff;
  h3 {
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 90px;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .num {
    font-size: 60px;
    font-weight: 700;

    i {
      font-style: inherit;
    }
  }
  ul {
    position: absolute;
    bottom: 199px;
    li {
      position: relative;
      padding-left: 10px;
      font-weight: 600;
      line-height: 30px;
      i {
        position: absolute;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;

        top: 50%;
        left: 0;
        margin-top: -2px;
        box-shadow: 0px 2px 7px 0px #666;
      }
      span {
        font-size: 18px;
      }
      .li-name {
        display: inline-block;
        margin-right: 42px;
      }
      &:nth-child(1) {
        i {
          background: #4ac913;
        }
      }
      &:nth-child(2) {
        i {
          background: #0bceeb;
        }
      }
      &:nth-child(3) {
        i {
          background: #ff708b;
        }
      }
      &:nth-child(4) {
        i {
          background: #ff9650;
        }
      }
      &:nth-child(5) {
        i {
          background: #cf61dc;
        }
      }
    }
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-image: linear-gradient(
    to bottom,
    rgba(56, 140, 136, 1),
    rgba(56, 140, 136, 0) 100%
  );
  h3 {
    font-size: 1.2xrem;
    font-weight: 700;
    padding: 0 2rem;
    color: #fff;
  }
  .logo {
    display: block;
    right: 0;
    width: 4rem;
    height: 4rem;
    background: url("../assets/logo.png") no-repeat center center;
    background-size: contain;
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  height: 25%;
  width: 100%;
  padding: 1rem;
  background-image: linear-gradient(
    to top,
    rgba(56, 140, 136, 1) 35%,
    rgba(56, 140, 136, 0) 100%
  );
  color: #fff;
  justify-items: center;
  align-items: center;
  ul {
    li {
      position: relative;
      padding-left: 10px;
      line-height: 20px;
      i {
        position: absolute;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;

        top: 50%;
        left: 0;
        margin-top: -2px;
        box-shadow: 0px 2px 7px 0px #666;
      }
      span {
        font-size: 0.8rem;
      }
      .li-name {
        display: inline-block;
        margin-right: 10px;
      }
      &:nth-child(1) {
        i {
          background: #4ac913;
        }
      }
      &:nth-child(2) {
        i {
          background: #0bceeb;
        }
      }
      &:nth-child(3) {
        i {
          background: #ff708b;
        }
      }
      &:nth-child(4) {
        i {
          background: #ff9650;
        }
      }
      &:nth-child(5) {
        i {
          background: #cf61dc;
        }
      }
    }
  }
  .total {
    flex: 1;
    text-align: center;
    p {
      font-size: 0.9rem;
    }
    .num {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
}
.block {
  display: none;
}

// 自定义覆盖物位置
/deep/ .amap-marker {
  //上
  .top {
    z-index: 200;
    margin-top: 25px;
    &::after {
      /* 定位 */
      position: absolute;
      left: 50%;
      margin-left: -8px;
      bottom: -16px;
    }
  }
  //下
  .bottom {
    margin-top: 120px;
    &::after {
      border-color: transparent transparent #fffff8 transparent;

      /* 定位 */
      position: absolute;
      left: 50%;
      margin-left: -8px;
      top: -16px;
    }
  }
  //左
  .left {
    margin-top: 74px;
    transform: translate3d(-80px, 0px, 0px);
    &::after {
      border-color: transparent transparent transparent #fffff8;
      /* 定位 */
      position: absolute;
      right: -15px;
    }
  }
  //右
  .right {
    margin-top: 74px;
    margin-left: calc(50% + 25px);
    &::after {
      border-color: transparent #fffff8 transparent transparent;
      /* 定位 */
      position: absolute;
      left: calc(50% + 10px);
    }
  }
}
@media only screen and (max-width: 1200px) {
  .left-content {
    display: none;
  }
  .logo {
    display: none;
  }
  /deep/ .custom-content-marker {
    padding: 5px;
    .iconT {
      width: 1.9rem;
      height: 1.9rem;
      background-size: 1.2rem;
      border-radius: 4px;
    }
    .txt {
      line-height: 1rem;
      p {
        font-size: 0.8rem;
      }
      span {
        font-size: 0.8rem;
        i {
          font-size: 1rem;
        }
      }
    }
  }
  /deep/ .txt {
    height: 2rem;
  }
  .block {
    display: block;
  }
  /deep/ .amap-marker {
    //上
    .top {
      margin-top: 10px;
    }
    //下
    .bottom {
      margin-top: 87px;
    }
    //左
    .left {
      margin-top: 50px;
      transform: translate3d(-60px, 0px, 0px);
      &::after {
        border-color: transparent transparent transparent #fffff8;
        /* 定位 */
        position: absolute;
        right: -15px;
      }
    }
    //右
    .right {
      margin-top: 50px;
      margin-left: calc(50% + 25px);
    }
  }
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
</style>
